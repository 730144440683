<!--  -->
<template>
  <div class="main_box site_station">
    <div class="nav_menu">
      <div class="menu_left">
        <el-input
          placeholder="请输入站点名称"
          class="search_input"
          v-model="siteName"
        ></el-input>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleSite('add')"
          >新增站点</el-button
        >
      </div>
    </div>
    <div class="station_table">
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column label="照片" width="180">
          <template scope="scope">
            <div v-if="scope.row.img">
              <el-image
                class="station_img"
                :src="scope.row.img"
                :preview-src-list="[scope.row.img]"
              >
              </el-image>
              <!-- <viewer>
                <img class="station_img" :src="scope.row.img" />
              </viewer> -->
            </div>

            <div v-else class="station_photo"></div>
          </template>
        </el-table-column>
        <el-table-column prop="siteName" label="站点名称"> </el-table-column>
        <el-table-column prop="head" label="负责人"> </el-table-column>
        <el-table-column prop="headPhone" label="负责人电话"> </el-table-column>
        <el-table-column prop="gateNum" label="闸门个数"> </el-table-column>
        <el-table-column
          prop="deviceAddress"
          label="设备地址码"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="departmentName"
          label="设备经纬度"
          :show-overflow-tooltip="true"
        >
          <template scope="scope">
            <span>{{ scope.row.lng }},{{ scope.row.lat }}</span>
          </template>
        </el-table-column>

        <el-table-column width="250" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" @click="handleSite('edit', scope.row)"
              >编辑</el-button
            >
            <el-button class="delete" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.page"
        :page-size="5"
      >
      </el-pagination>
    </div>
    <el-dialog
      class="border-r-8 role-dialog"
      :visible.sync="addShow"
      width="calc(388/1920*100vw)"
      :destroy-on-close="true"
      @close="resetClose"
    >
      <div slot="title" class="dialogTitle t-x font-s-18">{{ title }}</div>
      <div class="contentAdd" style="margin-bottom: 10px">
        <el-form :model="siteInfo" ref="ruleForm" :rules="ruleForm">
          <el-form-item prop="siteName" style="margin-top:calc(25/1080*100vh)">
            <span class="label_r">站点名称</span>
            <el-input
              v-model="siteInfo.siteName"
              placeholder="请输入站点名称"
              maxlength="8"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="deviceAddress"
            style="margin-top:calc(20/1080*100vh)"
          >
            <span class="label_r">设备地址码</span>
            <el-input
              v-model="siteInfo.deviceAddress"
              :readonly="this.title == '站点档案管理-新增' ? false : true"
              placeholder="请输入设备地址码"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item prop="head" style="margin-top:calc(20/1080*100vh)">
            <span class="label_r">负责人名称</span>
            <el-input
              v-model="siteInfo.head"
              :readonly="true"
              placeholder="请输入负责人"
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item prop="headPhone" style="margin-top:calc(20/1080*100vh)">
            <span class="label_r">负责人电话</span>
            <el-input
              placeholder="请输入负责人电话"
              v-model="siteInfo.headPhone"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item prop="gateNum" style="margin-top:calc(20/1080*100vh)">
            <span class="label_r">闸门个数</span>
            <el-input
              placeholder="请输入闸门个数"
              v-model="siteInfo.gateNum"
              maxlength="2"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-top:calc(20/1080*100vh)">
            <span class="label_r">经纬度选点</span>
            <LocationSelection
              :key="addShow"
              area="东营区"
              :location="coordinate"
              @locationSelection="locationSelection"
              style="width: calc(244 / 1920 * 100vw); height: calc(40/1080*100vh);"
            />
          </el-form-item>
          <el-form-item
            prop="coordinate"
            class="mgb"
            style="margin-top:calc(20/1080*100vh)"
          >
            <span class="label_r">设备经纬度</span>
            <el-input
              placeholder="请输入设备经纬度"
              v-model="coordinate"
              :readonly="true"
            ></el-input>
          </el-form-item>
          <el-form-item class="photos" style="margin-top:calc(20/1080*100vh)">
            <span class="label_r">上传照片</span>
            <el-upload
              class="img_upload"
              action="https://upload.xuruidea.com/uploadFile"
              drag
              accept="image/jpeg, image/gif, image/png, image/jpg"
              :show-file-list="false"
              :on-progress="handleLoading"
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
              :file-list="fileList"
            >
              <img
                class="img_upload1"
                v-if="siteInfo.img"
                :src="siteInfo.img"
                alt=""
              />
              <div class="images" v-else>
                <i class="el-icon-plus"></i>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleShow" class="guanBi">关闭</el-button>
        <el-button @click="handleSave" type="primary">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { siteAdd, sideDelete, sideUpdate } from "../../../api/index";
import LocationSelection from "../../../components/LocationSelection/index.vue";
import { isvalidPhone, isvalidGate, guardFall } from "./validate";
var isGuardFall = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入设备地址码"));
  } else if (!guardFall(value)) {
    callback(new Error("请输入正确的设备地址码"));
  } else {
    callback();
  }
};

var validPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入电话号码"));
  } else if (!isvalidPhone(value)) {
    callback(new Error("请输入正确的11位手机号码"));
  } else {
    callback();
  }
};
var validGate = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入电话号码"));
  } else if (!isvalidGate(value)) {
    callback(new Error("请输入正确的11位手机号码"));
  } else {
    callback();
  }
};

export default {
  components: {
    LocationSelection
  },
  data() {
    return {
      tableData: [],
      pagination: {
        // 分页
        size: 5,
        page: 1,
        total: 0
      },
      siteName: "", //站点名称
      siteInfo: {
        // 站点信息
        coordinate: ""
      },
      addShow: false,
      title: "", // 站点名称
      ruleForm: {
        img: [
          {
            required: true,
            message: "请上传图片",
            trigger: "change"
          }
        ],
        siteName: [
          {
            required: true,
            message: "请输入站点名称",
            trigger: "change"
          }
        ],
        deviceAddress: [
          {
            required: true,
            message: "请输入设备地址码",
            trigger: "change"
          },
          {
            required: true,
            message: "请输入正确的设备地址码",
            trigger: "change",
            validator: isGuardFall
          }
        ],
        headPhone: [
          {
            required: true,
            message: "请输入负责人电话",
            trigger: "change"
          },
          {
            required: true,
            message: "请输入正确的负责人电话",
            trigger: "change",
            validator: validPhone
          }
        ],
        gateNum: [
          {
            required: true,
            message: "请输入闸门个数",
            trigger: "change"
          },
          {
            required: true,
            message: "请输入正确的闸门个数",
            trigger: "change",
            validator: validGate
          }
        ],
        head: [
          {
            required: true,
            message: "请输入负责人名称",
            trigger: "blur"
          }
        ]
      },
      type: false, // 设备地址码
      regCoor: "",
      loading: true,
      loading1: true,
      coordinate: "",
      fileList: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    getList() {
      this.loading = true;
      let { page, size } = this.pagination;
      this.$get(`/site/page`, {
        page: page,
        size: size
      })
        .then(res => {
          this.tableData = res.data.list;
          this.pagination.total = res.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCurrent(page) {
      this.pagination.page = page;
      this.getList();
    },
    handleReset() {
      this.siteName = "";
      this.getList();
    },
    handleSearch() {
      if (this.siteName == "") {
        this.getList();
      } else {
        this.$get("/site/page", {
          page: 1,
          size: 5,
          siteName: this.siteName
        }).then(res => {
          this.tableData = res.data.list;
          this.pagination.total = res.data.total;
        });
      }
    },
    handleShow() {
      this.addShow = !this.addShow;
      this.siteInfo = {};
    },
    handleSite(type, row) {
      this.coordinate = "";
      this.loading1 = true;
      this.addShow = true;
      if (type == "add") {
        this.title = "站点档案管理-新增";
        this.siteInfo.head = window.localStorage.getItem("userName");
        this.type = true;
        // this.siteInfo = {};
      } else {
        this.type = false;
        this.title = "站点档案管理-编辑";
        let coordinate = "";
        this.siteInfo = JSON.parse(JSON.stringify(row));
        if (this.siteInfo.lng && this.siteInfo.lat) {
          this.coordinate = this.siteInfo.lng + "," + this.siteInfo.lat;
        } else {
          this.coordinate = "";
        }
        this.$forceUpdate();
      }
    },
    // 保存
    handleSave() {
      let arr;
      let {
        coordinate,
        head,
        headPhone,
        siteName,
        gateNum,
        deviceAddress,
        deviceCard,
        img
      } = this.siteInfo;
      console.log(img);
      arr = this.coordinate.split(",");
      // true 新增
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.type) {
            siteAdd({
              deviceAddress,
              deviceCard,
              siteName,
              head,
              headPhone,
              gateNum,
              longitude: arr[0],
              latitude: arr[1],
              img
            })
              .then(res => {
                if (res.code == 200) {
                  this.$message({
                    message: res.message,
                    type: "success"
                  });
                  this.addShow = false;
                  this.siteInfo = {};
                  this.pagination.page = 1;
                  this.getList();
                  this.$store.commit("getNavItems");
                } else {
                  this.$message({
                    message: res.message,
                    type: "error"
                  });
                }
              })
              .catch(err => {
                // console.log(err);
                this.$message({
                  message: err.message,
                  type: "error"
                });
              });
          } else {
            // 编辑
            sideUpdate({
              id: this.siteInfo.id,
              siteName: siteName,
              headPhone,
              gateNum,
              deviceAddress,
              head,
              longitude: arr[0],
              latitude: arr[1],
              img
            })
              .then(res => {
                if (res.code == 200) {
                  this.$message({
                    message: res.message,
                    type: "success"
                  });
                  this.getList();
                } else {
                  this.$message({
                    message: res.message,
                    type: "error"
                  });
                  this.getList();
                }
              })
              .catch(err => {
                this.$message({
                  message: err.message,
                  type: "error"
                });
              })
              .finally(() => {
                this.addShow = false;
              });
          }
        } else {
          return;
        }
      });
    },
    // 删除
    handleDelete(row) {
      this.$confirm("此操作将永久删除该站点，确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          sideDelete({
            id: row.id,
            deviceAddress: row.deviceAddress
          })
            .then(res => {
              if (res.code == 200) {
                this.$message({
                  message: res.message,
                  type: "success"
                });
                this.$store.commit("getNavItems");
                if (this.pagination.total % 5 == 1) {
                  this.pagination.page--;
                }
                console.log(this.pagination);
              } else {
                this.$message({
                  message: res.message,
                  type: "error"
                });
              }
            })
            .catch(err => {
              this.$message({
                message: err.message,
                type: "error"
              });
            })
            .finally(() => {
              this.getList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 图片大小检测
    beforeUpload(file) {
      if (
        !file.type.includes("image/png") &&
        !file.type.includes("image/jpeg") &&
        !file.type.includes("image/gif")
      ) {
        this.$message.error("请不要选择图片以外的文件上传");
        return false;
      }
      if (file.size > 1024 * 1024 * 10) {
        this.$message.error("请选择小于10MB的图片上传");
        return false;
      }
    },
    handleSuccess(res, file, fileList) {
      this.siteInfo.img = res.data.httpsUrl;
      this.fileList.push(file);
      this.loading1 = false;
    },
    handleLoading() {
      this.loading1 = true;
    },
    // 经纬度选点
    locationSelection(e) {
      if (e.point) {
        this.coordinate = `${e.point.lng},${e.point.lat}`;
      }
    },
    resetClose() {
      this.siteInfo = {};
    }
  },
  created() {
    this.getList();
  },
  mounted() {}
};
</script>
<style lang='scss' scoped>
.station_table {
  ::v-deep .el-table__row {
    height: calc(130 / 1080 * 100vh) !important;
  }
  ::v-deep .el-table__header-wrapper {
    margin-bottom: calc(8 / 1080 * 100vh);
  }
  .station_photo {
    width: calc(139 / 1980 * 100vw);
    height: calc(91 / 1080 * 100vh);
    padding: 40px !important;
    box-sizing: border-box;
    // background: burlywood;
    background: url("../../../assets/images/no_img.png") no-repeat;
    background-size: calc(145 / 1980 * 100vw) calc(98 / 1080 * 100vh);
    margin: 0 auto;
  }
  .station_img {
    width: calc(139 / 1980 * 100vw);
    height: calc(91 / 1080 * 100vh);
  }
}
.site_station {
  ::v-deep .el-dialog {
    width: calc(388 / 1980 * 100vw) !important;
    // height: calc(430 / 1080 * 100vh) !important;
  }
  .el-input,
  .el-textarea,
  .el-select,
  .images {
    width: calc(244 / 1920 * 100vw);
  }
  .images {
    height: calc(60 / 1080 * 100vh);
    i {
      width: calc(59 / 1920 * 100vw);
      height: calc(59 / 1080 * 100vh);
      border: 1px solid #e6e6e6;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ::v-deep .el-form-item__content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: flex-start;
  }
}
::v-deep .el-form-item {
  margin-bottom: calc(10 / 1080 * 100vh);
}
.photos {
  .label_r {
    line-height: 20px !important;
  }
}
.mgb {
  margin-bottom: calc(20 / 1080 * 100vh);
}
::v-deep .el-input.search_input {
  width: calc(175 / 1920 * 100vw);
}
::v-deep .img_upload {
  width: calc(224 / 1920 * 100vw);
  height: calc(60 / 1080 * 100vh);
  // .el-upload-dragger {
  //   width: calc(61 / 1920 * 100vw);
  //   height: calc(53 / 1080 * 100vh);
  // }
}
.img_upload1 {
  width: calc(224 / 1920 * 100vw);
  height: calc(150 / 1080 * 100vh);
  ::v-deep .el-upload-dragger {
    width: 61px;
    height: 53px;
  }
}
::v-deep .el-upload-dragger {
  width: calc(224 / 1920 * 100vw) !important;
  height: calc(150 / 1080 * 100vh) !important;
}
::v-deep .el-icon-circle-close {
  font-size: 40px;
  color: #ffffff;
}
::v-deep .el-form-item__error {
  top: 40px !important;
  left: calc(125 / 1920 * 100vw) !important;
}
::v-deep .el-form-item__content {
  height: 54px;
}
::v-deep .el-upload-dragger {
  border: none !important;
}

::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;
  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }
  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;
    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }
  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }
  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }
  .label_r {
    margin-left: 18px !important;
    width: calc(85 / 1920 * 100vw);
    text-align: left;
    color: #666;
    // margin-bottom: 10px;
  }
}
::v-deep .el-image-viewer__close {
  width: 72px;
  height: 72px;
  > .el-icon-circle-close {
    font-size: 70px;
    &:before {
      content: "\e78d" !important;
    }
  }
}
</style>
